<template>
  <template v-if="show">
    <div
      class="bg-purples-dark absolute right-5 top-[30px] z-[100] block h-3 w-4"
      style="clip-path: polygon(0% 100%, 50% 0%, 100% 100%)"
    />
    <div
      class="bg-purples-dark absolute top-10 z-10 flex w-full max-w-sm gap-2 rounded-lg p-4 text-white"
    >
      <div class="flex flex-col">
        <span class="font-semibold leading-6"
          >You have ${{ credit }} to spend</span
        >
        <span class="font-base text-sm leading-5 tracking-[0.015em]"
          >We’ve given you ${{ credit }} to spend on safety glasses. The credit
          will be automatically applied in your cart.</span
        >
      </div>
      <button
        class="self-start"
        aria-label="Close"
        lang="en"
        @click.stop="show = false"
      >
        <ZenniIconsIconClose class="text-white" size="base" />
      </button>
    </div>
  </template>
</template>
<script setup lang="ts">
import { ref } from '#imports'
withDefaults(defineProps<{ credit: number }>(), {
  credit: 100,
})
const show = ref(true)
</script>
