<template>
  <!-- the min-h-[100vh] is needed when scrolling for making the header sticky. -->
  <div
    class="flex flex-col overflow-visible"
    :class="scrollView ? 'min-h-[100vh]' : 'h-[100vh]'"
  >
    <div ref="obsTracker" class="h-1 w-full" />
    <Header :scroll-view="scrollView" />
    <main class="relative flex flex-1 flex-col">
      <NuxtLoadingIndicator />
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { useAsyncData, ref } from '#imports'
import { useGuestStore } from '~/store/guest'

const { gift } = useGuestStore()
const { getSiteConfiguration } = useSiteConfigurationStore()

const obsTracker = ref<HTMLElement>()
const scrollView = ref(false)
useIntersectionObserver(
  obsTracker,
  ([{ isIntersecting }]) => {
    scrollView.value = !isIntersecting
  },
  {
    rootMargin: '22px',
    threshold: 0.5,
  },
)

useAsyncData(async () => {
  if (gift && gift.code) {
    const storeCode = gift.code.replaceAll('_', ' ') as string
    await getSiteConfiguration(storeCode)
  }
})
</script>
