<template>
  <div
    v-if="!hideLanguages"
    class="bg-grays-lightest text-grays-dark flex items-center justify-end space-x-6 px-6 py-2 text-xs font-semibold"
    role="navigation"
    aria-label="Language selection"
  >
    <button aria-label="Switch to English" lang="en">English</button>
    <button aria-label="Switch to Spanish" lang="es">Español</button>
    <button aria-label="Switch to Chinese" lang="zh">中文</button>
  </div>
  <div
    class="border-grays-light sticky top-0 z-30 flex flex-wrap justify-between gap-y-3 bg-white px-6 py-4 lg:flex-row lg:items-center lg:gap-y-4 lg:py-4"
  >
    <div class="grid grid-cols-2 gap-3">
      <nuxt-link to="/" class="flex flex-1 justify-start" aria-label="Homepage">
        <NuxtImg
          src="/img/icons/zenni-business-logo.svg"
          alt="zenni logo"
          class="text-grays-darkest h-[40px]"
      /></nuxt-link>
      <div v-show="showPartnerLogo" class="border-grays-light border-l px-3">
        <NuxtImg
          :src="partnerLogo"
          provider="cloudflare"
          alt="Partner logo"
          height="40"
          :modifiers="{ fit: 'contain' }"
          @load="changeShowPartnerLogo(true)"
          @error="changeShowPartnerLogo(false)"
        />
      </div>
    </div>
    <div
      v-if="showSearch"
      class="order-3 hidden w-full lg:order-2 lg:flex lg:w-fit lg:px-10"
      role="search"
    >
      <PlpSearch
        v-model="searchKeyword"
        class="w-full md:w-2/3 lg:w-[343px]"
        aria-label="Search products"
        @debounce-perform-search="debounceUpdateSearch"
        @perform-search="clearSearch"
      />
    </div>
    <div class="relative order-2 flex items-center justify-end lg:order-3">
      <nav
        v-if="showNav"
        class="hidden lg:relative lg:top-0 lg:flex lg:flex-row lg:gap-0 lg:space-x-8 lg:border-0 lg:p-0"
        aria-label="Main navigation"
      >
        <nuxt-link
          v-for="(link, i) in links"
          :key="`nav-link-${i}`"
          :href="link.href"
          :target="link.target"
          class="font-semibold"
          @click.stop="closeAndContinue"
          >{{ link.label }}</nuxt-link
        >
      </nav>
      <nav
        v-if="showNav && showMenuItem"
        class="absolute right-0 top-12 z-20 flex flex-col gap-6 whitespace-nowrap border bg-white p-6 text-center lg:top-0 lg:hidden lg:flex-row lg:gap-0 lg:space-x-8 lg:border-0 lg:p-0"
        aria-label="Main navigation"
      >
        <nuxt-link
          v-for="(link, i) in links"
          :key="`nav-link-${i}`"
          :href="link.href"
          :target="link.target"
          class="font-semibold"
          @click.stop="closeAndContinue"
          >{{ link.label }}</nuxt-link
        >
      </nav>
      <div v-if="!hideCart" class="flex items-center gap-4 pl-10">
        <NuxtLink
          to="/cart-preview"
          aria-label="Cart"
          :data-count="itemCount"
          class="after:bg-teal-primary relative after:absolute after:-top-1 after:left-3 after:flex after:h-4 after:w-4 after:items-center after:justify-center after:rounded-full after:text-xs after:font-semibold after:text-white after:content-[attr(data-count)] data-[count='0']:after:hidden data-[count='0']:after:content-['']"
        >
          <ZenniIconsIconCart size="base" class="text-grays-dark" />
        </NuxtLink>

        <button
          v-if="showNav"
          class="lg:hidden"
          aria-label="Navigate"
          @click="showMenuItem = !showMenuItem"
        >
          <ZenniIconsIconNavigate size="base" class="text-grays-dark" />
        </button>
      </div>
    </div>
  </div>
  <div v-if="showSearch" class="z-20 bg-white px-4 pb-3 lg:hidden">
    <PlpSearch
      v-model="searchKeyword"
      class="w-full"
      aria-label="Search products"
      @debounce-perform-search="debounceUpdateSearch"
      @perform-search="clearSearch"
    />
  </div>
  <AppHeaderCreditBar
    v-if="!hideCart"
    class="border-grays-light sticky z-10 w-full justify-end border-b bg-white px-4 pb-4"
    :class="[
      scrollView
        ? 'shadow-[0px_2px_0px_0px_rgba(0,0,0,0),0px_8px_8px_4px_rgba(0,0,0,0.1)]'
        : 'shadow-none',
      hideLanguages ? 'top-[72px]' : 'top-[70px] lg:top-24',
    ]"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { debounce } from 'lodash-es'
import { useGuestStore } from '~/store/guest'
import { ref, computed, useRouter, useRoute } from '#imports'
import { useGuestCartStore } from '~/store/guestCart'

const { itemCount } = storeToRefs(useGuestCartStore())

defineProps<{ scrollView: boolean }>()
const showMenuItem = ref<boolean>(false)

const route = useRoute()
const router = useRouter()
const guestStore = useGuestStore()

const isPLP = computed(() => route.path.includes('/products/list'))
const query = route.query?.query

const searchKeyword = ref<string>(
  (query && isPLP.value ? (query as string) : '') || '',
)

const links = [
  {
    label: 'Safety Glasses',
    href: '/products/list',
    target: undefined,
  },
  { label: 'Contact', href: '/contact', target: undefined },
]

const closeAndContinue = (event: Event) => {
  showMenuItem.value = false
  event.target?.dispatchEvent(event)
}

const showPartnerLogo = ref<boolean>(false)

const showSearch = computed(() => route.meta.showSearch ?? true)
const showNav = computed(() => route.meta.showNav ?? true)
const hideCart = computed(() => route.meta.hideCart ?? false)
const hideLanguages = computed(() => route.meta.hideLanguages ?? false)

const changeShowPartnerLogo = (value: boolean) => {
  showPartnerLogo.value = value
}

const partnerLogo = computed(() => {
  return `logo/${guestStore.gift?.partnerId}.image`
})

const searchProducts = () => {
  // This is to not triggering the search if we clear the search input on other pages than PLP
  if (!query && !isPLP.value && !searchKeyword.value) {
    return
  }

  router.replace({
    path: '/products/list',
    query: {
      ...route.query,
      query: searchKeyword.value,
    },
  })
}

const debounceUpdateSearch = debounce(searchProducts, 500)
const clearSearch = () => {
  searchKeyword.value = ''
  searchProducts()
}
</script>
