<template>
  <div
    class="relative flex cursor-pointer items-center space-x-2"
    @click="showInfoModal = true"
  >
    <span class="text-purples-dark font-semibold"
      >Credit left: {{ formattedBalance }}</span
    >
    <AppHeaderCreditTooltip :credit="giftAmount" />
    <ZenniIconsIconInformationCircle
      class="stroke-purples-dark [&>path]:fill-purples-dark fill-none [&>path]:stroke-none"
      size="base"
    />
    <ZnModal
      size="small"
      :show-close-icon="false"
      :show="showInfoModal"
      @close="showInfoModal = false"
    >
      <template #title>
        <h1 class="text-lg font-semibold">How credit works</h1>
      </template>
      <template #body>
        <ul class="mt-5 list-disc space-y-5 px-6">
          <li>
            Credit is automatically applied to your account and works like a
            gift card - feel free to apply it across multiple purchases.
          </li>
          <li>
            Credit can be applied to all products from our current catalog,
            including taxes and shipping.
          </li>
          <li>You're responsible for any costs over your credit amount.</li>
          <li>
            Credit must be used by
            <span class="font-semibold">{{ giftEndDate }}</span> and does not
            carry over or transfer.
          </li>
        </ul>
        <div class="mt-5 flex">
          <ZnButton
            class="w-full text-center"
            type="secondary"
            @click="showInfoModal = false"
            >Close</ZnButton
          >
        </div>
      </template>
    </ZnModal>
  </div>
</template>

<script setup lang="ts">
import { format, parseISO } from 'date-fns'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCart } from '@/composables/useCart'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { ref, computed } from '#imports'

const { path } = useRoute()
const { cart } = useCart()
const { gift: userGift, giftAmount } = useGuestStore()
const guestCartStore = useGuestCartStore()
const { cart: guestCart } = storeToRefs(guestCartStore)

const showInfoModal = ref<boolean>(false)

const cartTotal = computed<number>(() => {
  const activeCart = cart.value ?? guestCart.value

  return path === '/checkout/orderreview'
    ? activeCart?.prices?.grand_total?.value ?? 0
    : activeCart?.prices?.subtotal_excluding_tax?.value ?? 0
})

const formattedBalance = computed<string>(() => {
  const amount = giftAmount - cartTotal.value
  return formatCurrency(amount > 0 ? amount : 0, 'en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
  })
})

const giftEndDate = computed<string>(() => {
  const { to } = userGift?.schedule?.date ?? {}
  if (to) {
    return format(parseISO(to), 'MM/dd/yyyy')
  }
  return '--'
})
</script>
